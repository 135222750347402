<template>
  <div>
    <div class="flex flex-wrap gap-5 mb-5 mt-5" v-if="virtualCard">
      <div class="flex flex-col bg-blue-950 text-white p-6 w-7/12 rounded">
        <div class="flex justify-between text-blue-200 text-11 mb-2">
          <span>Card Balance</span>
          <span
            :class="
              this.virtualCard.status !== 'active'
                ? 'text-orange-400'
                : 'text-green-400'
            "
          >
            {{ this.virtualCard.status }}
          </span>
        </div>
        <div class="text-18 font-bold mr-2 mb-2">
          {{ this.virtualCard.balance | formatDollarAmount }}
        </div>
        <div
          class="flex flex-wrap gap-4 items-center justify-between mr-2 mb-2"
        ></div>
      </div>
    </div>

    <div v-if="virtualCardTransactions.show">
      <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
        <div class="col-span-1 flex items-center flex-wrap">
          <h2 class="text-lg font-bold mr-3">Transactions</h2>
        </div>
        <div class="col-span-1">
          <div class="relative">
            <search-form
              v-model="virtualCardTransactions.query"
              placeholder="Search for reference, status, etc."
              @submit="(event) => $refs.table.loadAjaxData()"
            />
          </div>
        </div>
      </div>
      <datatable
        ref="table"
        :url="virtualCardTransactions.url"
        :ajax="true"
        :ajax-pagination="true"
        :columns="virtualCardTransactions.columns"
        :fillable="false"
        :query="virtualCardTransactions.query"
        :index="true"
        :sorted="true"
      />
    </div>
    <template v-else>
      <h3 class="text-center text-lg mt-5">No Wallet Found</h3>
    </template>
  </div>
</template>
<script>
export default {
  // components: {
  //   WalletCard: () => import('./WalletCard'),
  // },
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      requestError: '',
      virtualCard: null,
      virtualCardTransactions: this.$options.resource([], {
        query: '',
        url: `${this.$baseurl}/cards/admin/transactions?userId=${this.staff?.id}`,
        show: true,
        columns: [
          {
            name: 'amount',
            th: 'Amount',
            render: (transaction) =>
              `$${this.$options.filters.currency(transaction.amount)}`,
          },

          {
            name: 'reference',
            th: 'Reference',
            render: (transaction) => transaction?.id || 'N/A',
          },

          {
            name: 'narration',
            th: 'Narration',
            render: (transaction) => transaction.narration || 'N/A',
          },
          {
            name: 'timestamp',
            th: 'Date Created',
            render: (transactions) => {
              return this.$options.filters.dateFormat(
                transactions?.createdAt,
                'D, dd M Y'
              );
            },
          },
          {
            name: 'type',
            th: 'Transaction Type',
            render: (transaction) => transaction.type || 'N/A',
          },

          {
            name: 'status',
            th: 'Status',
            render: (transaction) => {
              switch (transaction.status) {
                case 'SUCCESS':
                  return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                                Success
                              </div>`;
                case 'pending':
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                                Pending
                            </div>`;

                case 'FAILED':
                  return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
                                Failed
                            </div>`;
              }
            },
          },
        ],
      }),
    };
  },

  methods: {
    async getUserVirtualCard() {
      await this.sendRequest(
        'admin.users.virtualCards.getUserVirtualCard',
        this.staff.id,
        {
          success: (res) => {
            this.virtualCard = res?.data?.data;
            this.showTransactions();
          },
          error: (error) => {
            console.error(error);
          },
        }
      );
    },

    showTransactions() {
      this.virtualCardTransactions.show = true;
    },
  },
  mounted() {
    this.getUserVirtualCard();
  },
};
</script>
